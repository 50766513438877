export const constants = {
  layouts: {
    default: 1,
    checkout: 2,
  },
  regex: {
    password: /(?!.*[\s])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
    phone:
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    vehicleRegistration:
      /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/,
  },
  contentfulTypes: {
    cmsPage: "cmsPage",
    cmsPageSubsection: "cmsPageSubsection",
    button: "button",
    placeholder: "placeholder",
    homePage: "homePage",
    signupPage: "signupPage",
    deliveryInfoPage: "deliveryInfoPage",
    contactUs: "contactUs",
    aboutUs: "aboutUs",
    knowledgeHubListingPage: "knowledgeHubListingPage",
    knowledgeHubSection: "knowledgeHubSection",
    knowledgeHubBlog: "knowledgeHubBlog",
    blogCategory: "blogCategory",
    megaMenu: "megaMenu",
    dealsPage: "dealsPage",
    whyGsf: "whyGsf",
    categoryPage: "categoryPage",
    plpBanner: "plpBanner",
    makesAndModels: "makesmodels",
    organisation: "organisationSchema",
    sitemapLinks: "sitemapLinks",
  },
  contentfulSlugs: {
    privacyPolicy: "privacy-policy",
    cookiePolicy: "cookie-policy",
    termsAndConditions: "terms-and-conditions",
    storeLocator: "store-locator",
    returnsAndRefunds: "guest-returns",
    contact: "contact",
    clickAndCollect: "click-and-collect",
    aboutUs: "about-us",
    klarna: "klarna",
    pdpTabs: "pdp-bottom-section",
    accessories: "accessories",
    tools: "tools",
    entryId: "1BoN619Em9mazP3w9pNE93",
    menuItem: "menuItem",
  },
  cookies: {
    customerToken: "customer-token",
    customerTokenExpiryHours: 90 * 24,
    locale: "NEXT_LOCALE",
    selectedBranch: "branch-url",
    selectedBranchName: "branch-name",
    selectedVehicle: "customer-vehicle",
    specialCoupon: "specialCoupon",
    specialCouponInitalLoadCheck: "specialCouponInitalLoadCheck",
    smartBannerCouponApplied: "smartBannerCouponApplied",
    headerBannerClosed: "header_banner_closed",
    new_vehicle: "new_vehicle",
  },
  localStorage: {
    cartId: "cartId",
    cartAddressId: "cartShippingAddressId",
    selectedBranch: "selectedBranch",
    placeOrderToken: "placeOrderToken",
    recently_viewed: "recently_viewed",
    shouldFetchCartDetails: "shouldFetchCartDetails",
    isBranchSelected: "isBranchSelected",
  },
  currency: {
    USD: "$",
    GBP: "£",
    EUR: "€",
  },
  contentTypes: {
    category: "CATEGORY",
    product: "PRODUCT",
    makeandmodels: "MAKEANDMODELS",
    makeselected: "MAKESELECTED",
    modelselected: "MODELSELECTED",
    branches: "BRANCHES",
    branch: "BRANCHINFO",
    blogs: "BLOGSINFO",
    search_result: "SEARCHRESULT",
    sitemap: "SITEMAP",
    CMS_PAGE: "CMS_PAGE",
  },
  display_modes: {
    product_listing: "PRODUCTS",
    sub_category_listing: "PRODUCTS_AND_PAGE",
  },
  perPageSizes: {
    PLP: 10,
    subCategoryListing: 12,
  },
  storageKeys: {
    recently_viewed: "recently_viewed",
  },
  image_types: {
    brand: "brand",
    product: "product",
    category: "category",
    brand_recommended: "brand_recommended",
    product_recommended: "product_recommended",
    product_best_seller: "product_best_seller",
  },
  vehicle_fields: {
    model: "modelId",
    body: "bodyStyleId",
    make: "manufacturerId",
    year: "modelVersionId",
    engine: "engineSizeId",
    type: "type",
  },
  sortingOptions: {
    PLP: [
      {
        field: "price",
        direction: "asc",
        key: "price_low_to_high",
        label: "Price - Low to High",
      },
      {
        field: "price",
        direction: "desc",
        key: "price_high_to_low",
        label: "Price - High to Low",
      },
      {
        field: "name",
        direction: "asc",
        key: "product_title_asc",
        label: "Product Title (A to Z)",
      },
      {
        field: "name",
        direction: "desc",
        key: "product_title_desc",
        label: "Product Title (Z to A)",
      },
      {
        direction: "desc",
        label: "Best Seller",
        field: "best_seller",
        key: "best_seller_desc",
      },
    ],
  },
  pageSizes: [10, 20, 50],
  pageSizesSubcategory: [12, 24, 48],
  category_ids: {
    parts: 3,
    accessories: 3227,
  },
  time_format: ["h,m,s", "H,m,s"],
  Accessories: "accessories",
  featured_tools: "featured_tools",
  featured_accessory: "featured_accessory",
  image_extensions: [".gif", ".jpg", ".jpeg", ".png", ".svg", ".webp", ".bmp"],
  file_extensions: [".pdf", ".xml", ".css", ".js", ".ico"],
  next_routes: ["static/chunks", "static/css", "static/media"],
  paths: {
    home: "/",
    customer: ["/customer", "/loginascustomer"],
    cms: [
      "/about-us",
      "/click-and-collect",
      "/contact",
      "/cookies-policy",
      "/delivery-information",
      "/guest-returns",
      "/klarna",
      "/knowledge-hub",
      "/privacy-policy",
      "/terms-and-conditions",
    ],
    newsletter: "/newsletter",
    checkout: "/checkout",
    cart: "/checkout/cart",
    branch: "/branches",
    blog: "/blog",
    search: "/catalogsearch",
    makeAndModels: "/makesandmodels",
    sitemap: "/sitemap",
    product: "/products",
    category: ["/parts", "/tools", "/deals", "/accessories"],
  },
};
