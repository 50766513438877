"use client";

import { createInstance } from "i18next";
import { i18nConfig } from "./settings";
import { useParams } from "next/navigation";
import { constants } from "@/lib/utils/constants";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  initReactI18next,
  useTranslation as useTranslationOriginal,
} from "react-i18next";

const runsOnServerSide = typeof window === "undefined";

const i18nInstance = createInstance();
i18nInstance
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language, namespace) =>
      import(`../../locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    lng: undefined,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    defaultNS: "common",
    fallbackNS: "common",
    preload: runsOnServerSide ? i18nConfig.locales : [],
    detection: {
      order: ["path", "htmlTag", "cookie", "navigator"],
      lookupCookie: constants.cookies.locale,
      caches: [],
    },
  });

export function useTranslation(namespaces, options = {}) {
  const { locale } = useParams();
  const { t, i18n, ready } = useTranslationOriginal(namespaces, options);
  if (i18n.resolvedLanguage !== locale) {
    i18n.changeLanguage(locale);
  }
  return { t, i18n, ready, locale };
}
